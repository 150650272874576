import { marketPlaceConstants } from "src/constants";

const initialState = {};

export function marketPlace(state = initialState, action){
    switch(action.type){
        case marketPlaceConstants.GET_PRODUCT_LIST_SUCCESS:
        return {
            ...state,
            marketPlaceProdctList: action.payload && action.payload.products ? action.payload.products : [],
        };  
        case marketPlaceConstants.GET_ORDER_LIST_SUCCESS:
        return {
            ...state,
            marketPlaceOrderList: action.payload && action.payload.data && action.payload.data.orderDetail ? action.payload.data.orderDetail : [],
        };    
        default:
            return state;
    }
}
