import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CIcon
} from "@coreui/react";

import navigation from "./_nav";
import logo from "../logo.svg";
import logoBrand from "../logoIcon.svg";
import { getAllowedRoutes } from "../utils";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.changeState.sidebarShow);
  const loggedIn = useSelector((state) => state.authentication);

  let allowedRoutes = [];
  allowedRoutes = getAllowedRoutes(navigation);

  useEffect(() => {
    allowedRoutes = getAllowedRoutes(navigation);
  }, [loggedIn]);

  const renderNavItems = (items) => {
    const currentPath = window.location.hash.split("#")[1] || window.location.pathname;
    return items.map((item, index) => {
      console.log('--*-item route--**-', item)

      if (item._tag === "CSidebarNavItem") {
        return (
          <CSidebarNavItem
            key={index}
            name={item.name}
            to={item.to}
            icon={item.icon}
          />
        );
      }
      if (item._tag === "CSidebarNavDropdown") {
        const isDropdownOpen = item._children.some(child => child.to === currentPath);
        return (
          <CSidebarNavDropdown
            key={index}
            name={item.name}
            icon={item.icon}
            route={isDropdownOpen ? currentPath : ""}
          >
            {renderNavItems(item._children)}
          </CSidebarNavDropdown>
        );
      }
      return null;
    });
  };

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <img
          className="c-sidebar-brand-full"
          src={logo}
          height={100}
          width={150}
          style={{ marginLeft: "-99px" }}
          alt="Logo"
        />
        <img
          className="c-sidebar-brand-minimized"
          src={logoBrand}
          height={35}
          width={150}
          alt="Logo"
        />
      </CSidebarBrand>

      <CSidebarNav>
        {renderNavItems(allowedRoutes)}
      </CSidebarNav>

      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
