import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'

const TheLayout = () => {
  const history = useHistory();
  const { bankAccounts, totalInstantAvailable, bankErrors } = useSelector(state => state.user);

  const navigateToPayout = () => {
    history.push('/bank-account/list')
  }


  return (
    <>
      {(bankAccounts?.length == 0 || bankErrors?.length != 0) && totalInstantAvailable >= 2500 && <div
        className="cursor-pointer d-flex justify-content-center align-items-center bg-danger px-3"
        onClick={navigateToPayout}
      >
        {/*  <img src={config.ALERT_WHITE} width="35" height="35" /> */}
        <div className="text-white p-2">
          <p>
            Your wallet balance is $2500, Please add your bank account.
          </p>
        </div>
        {/* <span
            className="cursor-pointer text-white alert-close"
            //onClick={cancelInfo}
          >
            ×
          </span> */}
      </div>}
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">
            <TheContent />
          </div>
          <TheFooter />
        </div>
      </div>
    </>
  )
}

export default TheLayout
