import React from "react";
import { components } from "react-select/dist/react-select.cjs.prod";
import { marketPlaceActions } from "./actions";

const Toaster = React.lazy(() =>
  import("./views/notifications/toaster/Toaster")
);
const Tables = React.lazy(() => import("./views/base/tables/Tables"));

const Breadcrumbs = React.lazy(() =>
  import("./views/base/breadcrumbs/Breadcrumbs")
);
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
const BasicForms = React.lazy(() => import("./views/base/forms/BasicForms"));

const Jumbotrons = React.lazy(() =>
  import("./views/base/jumbotrons/Jumbotrons")
);
const ListGroups = React.lazy(() =>
  import("./views/base/list-groups/ListGroups")
);
const Navbars = React.lazy(() => import("./views/base/navbars/Navbars"));
const Navs = React.lazy(() => import("./views/base/navs/Navs"));
const Paginations = React.lazy(() =>
  import("./views/base/paginations/Pagnations")
);
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
const ProgressBar = React.lazy(() =>
  import("./views/base/progress-bar/ProgressBar")
);
const Switches = React.lazy(() => import("./views/base/switches/Switches"));

const Tabs = React.lazy(() => import("./views/base/tabs/Tabs"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));
const BrandButtons = React.lazy(() =>
  import("./views/buttons/brand-buttons/BrandButtons")
);
const ButtonDropdowns = React.lazy(() =>
  import("./views/buttons/button-dropdowns/ButtonDropdowns")
);
const ButtonGroups = React.lazy(() =>
  import("./views/buttons/button-groups/ButtonGroups")
);
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
const Charts = React.lazy(() => import("./views/charts/Charts"));
// const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const CoreUIIcons = React.lazy(() => import("./views/icons/coreui-icons/CoreUIIcons"));
const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
const Brands = React.lazy(() => import("./views/icons/brands/Brands"));
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));
const Badges = React.lazy(() => import("./views/notifications/badges/Badges"));
const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() => import("./views/theme/typography/Typography"));
const Widgets = React.lazy(() => import("./views/widgets/Widgets"));
const Users = React.lazy(() => import("./views/users/Users"));
const User = React.lazy(() => import("./views/users/User"));

const Customers = React.lazy(() => import("./views/customers/list"));
const CustomerEdit = React.lazy(() => import("./views/customers/edit"));
const Merchants = React.lazy(() => import("./views/merchants/list"));
const MerchantEdit = React.lazy(() => import("./views/merchants/edit"));
const Celebencers = React.lazy(() => import("./views/celebencers/list"));
const Transactions = React.lazy(() => import("./views/transactions/list"));
const CelebencerEdit = React.lazy(() => import("./views/celebencers/edit"));
const CelebencerView = React.lazy(() => import("./views/celebencers/view"));
const CommissionsList = React.lazy(() => import("./views/commission/list"));
const CommissionsEdit = React.lazy(() => import("./views/commission/edit"));
const AreaOfConcentratinList = React.lazy(() => import("./views/areaOfConcentration/list"));
const AreaOfConecentrationEdit = React.lazy(() => import("./views/areaOfConcentration/edit"));
const AreaOfConecentrationAdd = React.lazy(() => import("./views/areaOfConcentration/add"));

const emailsList = React.lazy(() => import("./views/emails/list"));
const emailEdit = React.lazy(() => import("./views/emails/edit"));

const CategoryList = React.lazy(() => import("./views/productCategories/list"));
const CategoryEdit = React.lazy(() => import("./views/productCategories/edit"));
const CategoryStore = React.lazy(() => import("./views/productCategories/store"));

const IndustriesList = React.lazy(() => import("./views/industries/list"));
const IndustryEdit = React.lazy(() => import("./views/industries/edit"));
const IndustryStore = React.lazy(() => import("./views/industries/store"));

const ColorList = React.lazy(() => import("./views/productColor/list"));
const ColorEdit = React.lazy(() => import("./views/productColor/edit"));
const ColorStore = React.lazy(() => import("./views/productColor/store"));
const GalleryList = React.lazy(() => import("./views/gallery/list"));
const ShoutoutList = React.lazy(() => import("./views/shoutout/list"));
const ShoutoutView = React.lazy(() => import("./views/shoutout/view"));
const StoreOrderList = React.lazy(() => import("./views/storeOrder/list"));
const PromotedProductOrderList = React.lazy(() =>
  import("./views/productPromotion/list.js")
);

const PromotedProductOrderView = React.lazy(() =>
  import("./views/productPromotion/view.js")
);
const StoreOrderView = React.lazy(() => import("./views/storeOrder/view"));
const liveOneOnOneList = React.lazy(() => import("./views/liveOneOnOne/list"));
const liveOneOnOneView = React.lazy(() => import("./views/liveOneOnOne/view"));
const ReferList = React.lazy(() => import("./views/referrer/list"));
const ReferEdit = React.lazy(() => import("./views/referrer/edit"));
const uvRepList = React.lazy(() => import("./views/uvRep/list"));
const uvRepEdit = React.lazy(() => import("./views/uvRep/edit"));
const uvRepStore = React.lazy(() => import("./views/uvRep/store"));
const bankAccountList = React.lazy(() => import("./views/bankAccount/list.js"));
const bankAccountAdd = React.lazy(() => import("./views/bankAccount/add.js"));
const bankAccountUpdate = React.lazy(() => import("./views/bankAccount/update.js"));
const message = React.lazy(() => import("./views/messages/message"));
const uvNewsFeed = React.lazy(() => import("./views/uvnewsfeed/list"));
const uvStore = React.lazy(() => import("./views/uvStore/list"));
const homePageList = React.lazy(() => import("./views/homePage/list"));
const groupChatList = React.lazy(() => import("./views/liveGroupChat/list"));
const groupChatView = React.lazy(() => import("./views/liveGroupChat/view"));
const EmailList = React.lazy(() => import("./views/EmailList/EmailList"));
const PerformerSubscriber = React.lazy(() =>
  import("./views/PerformerSubscriber/PerformerSubscriber")
);
const FaqList = React.lazy(() => import("./views/Faq/list"));
const FaqStore = React.lazy(() => import("./views/Faq/store"));
const FaqEdit = React.lazy(() => import("./views/Faq/edit"));

const TosList = React.lazy(() => import("./views/termsOfServices/list"));
const TosCreate = React.lazy(() => import("./views/termsOfServices/store"));
const TosEdit = React.lazy(() => import("./views/termsOfServices/edit"));

const personalAppearnceList = React.lazy(() => import("./views/personalApperance/list"));

const personalAppearnceView = React.lazy(() => import("./views/personalApperance/view"));

const RequestLimit = React.lazy(() => import("./views/RequestLimit/RequestLimit"));

const productRecommendations = React.lazy(() => import("./views/productRecommendations/ProductRecommendations"));

const marketplaceRecommendations = React.lazy(() => import("./views/productRecommendations/MarketPlaceRecommendation"));
const directMessage = React.lazy(() => import("./views/directMessage/list"));
const meetAndGreet = React.lazy(() => import("./views/meetAndGreet/list"));
const meetAndGreetView = React.lazy(() => import("./views/meetAndGreet/view"));
const selfLiveSalesOrder = React.lazy(() => import("./views/selfLiveSales/orderList"));
const selfLiveSalesOrderView = React.lazy(() => import("./views/selfLiveSales/orderDetail"));
const Wallet = React.lazy(() => import("./views/wallet/wallet"));
const marketPlacProducts = React.lazy(() => import("./views/marketPlace/productList"));
const marketPlacOrders = React.lazy(() => import("./views/marketPlace/orderList"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  // { path: "/admin/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/admin/customer",
    exact: true,
    name: "Customer",
    component: Customers,
    permission: "uv-rep",
  },
  {
    path: "/admin/customer/:id",
    exact: true,
    name: "Edit",
    component: CustomerEdit,
  },
  {
    path: "/admin/merchant",
    exact: true,
    name: "Brands",
    component: Merchants,
  },
  {
    path: "/admin/merchant/:id",
    exact: true,
    name: "Edit",
    component: MerchantEdit,
  },
  {
    path: "/admin/celebencers",
    exact: true,
    name: "Talents",
    component: Celebencers,
  },
  {
    path: "/admin/celebencers/:id",
    exact: true,
    name: "Edit",
    component: CelebencerEdit,
  },
  {
    path: "/uv-rep/celebencers",
    exact: true,
    name: "Talents",
    component: Celebencers,
  },
  {
    path: "/uv-rep/celebencers/:id",
    exact: true,
    name: "View",
    component: CelebencerView,
  },
  {
    path: "/admin/areaofconcentration",
    exact: true,
    name: "Areas Of Concentration",
    component: AreaOfConcentratinList,
  },
  {
    path: "/admin/areaofconcentration/add",
    exact: true,
    name: "Add",
    component: AreaOfConecentrationAdd,
  },
  {
    path: "/admin/areaofconcentration/:id",
    exact: true,
    name: "Edit",
    component: AreaOfConecentrationEdit,
  },

  {
    path: "/admin/commission",
    exact: true,
    name: "Commissions",
    component: CommissionsList,
  },
  {
    path: "/admin/commission/:id",
    exact: true,
    name: "Commissions",
    component: CommissionsEdit,
  },
  {
    path: "/admin/product-recommendations",
    exact: true,
    name: "Product Recommendations",
    component: productRecommendations,
  },
  {
    path: "/admin/marketplace-recommendations",
    exact: true,
    name: "MarketPlace Featured",
    component: marketplaceRecommendations,
  },
  {
    path: "/admin/emails",
    exact: true,
    name: "Email Templates",
    component: emailsList,
  },
  {
    path: "/admin/emails/:id",
    exact: true,
    name: "Edit Email Template",
    component: emailEdit,
  },
  {
    path: "/admin/product-category",
    exact: true,
    name: "UV Store Categories",
    component: CategoryList,
  },
  {
    path: "/admin/product-category/add",
    exact: true,
    name: "Add Category",
    component: CategoryStore,
  },
  {
    path: "/admin/product-category/:id",
    exact: true,
    name: "Edit Category",
    component: CategoryEdit,
  },
  {
    path: "/admin/product-color",
    exact: true,
    name: "Colors",
    component: ColorList,
  },
  {
    path: "/admin/product-color/add",
    exact: true,
    name: "Add Color",
    component: ColorStore,
  },
  {
    path: "/admin/product-color/:id",
    exact: true,
    name: "Edit Color",
    component: ColorEdit,
  },
  {
    path: "/admin/gallery",
    exact: true,
    name: "Gallery",
    component: GalleryList,
  },
  {
    path: "/admin/shoutout",
    exact: true,
    name: "Shoutouts",
    component: ShoutoutList,
  },
  {
    path: "/admin/shoutout/:id",
    name: "View Shoutout",
    component: ShoutoutView,
  },
  {
    path: "/admin/storeOrder",
    exact: true,
    name: "StoreOrder",
    component: StoreOrderList,
  },
  {
    path: "/admin/promoted-orders",
    exact: true,
    name: "Product Promotions",
    component: PromotedProductOrderList,
  },
  {
    path: "/admin/promotedOrders/:id",
    name: "View Product Promotion",
    component: PromotedProductOrderView,
  },
  {
    path: "/admin/storeOrder/:id",
    name: "View StoreOrder",
    component: StoreOrderView,
  },
  {
    path: "/admin/liveOneOnOne",
    exact: true,
    name: "live One On One",
    component: liveOneOnOneList,
  },
  {
    path: "/admin/liveOneOnOne/:id",
    name: "View live One On One",
    component: liveOneOnOneView,
  },
  {
    path: "/admin/referrer",
    exact: true,
    name: "Referrer",
    component: ReferList,
  },
  {
    path: "/admin/referrer/:id",
    exact: true,
    name: "Referrer Edit",
    component: ReferEdit,
  },
  {
    path: "/admin/uvRep",
    exact: true,
    name: "UV Representative",
    component: uvRepList,
  },
  { path: "/admin/uvRep/add", exact: true, name: "Add", component: uvRepStore },
  { path: "/admin/uvRep/:id", exact: true, name: "Edit", component: uvRepEdit },
  {
    path: "/bank-account/list",
    exact: true,
    name: "Bank Accounts",
    component: bankAccountList,
  },
  {
    path: "/bank-account/add",
    exact: true,
    name: "Attach Bank Account",
    component: bankAccountAdd,
  },
  {
    path: "/bank-account/update",
    exact: true,
    name: "Update Bank Account",
    component: bankAccountUpdate,
  },
  { path: "/admin/message", name: "Messages", component: message },
  { path: "/admin/uvnewsfeed", name: "UV News Feed", component: uvNewsFeed },
  { path: "/admin/uvstore", name: "UV Store", component: uvStore },
  { path: "/admin/homePage", name: "Home Page", component: homePageList },
  { path: "/admin/group-chat", name: "Group Chat", component: groupChatList },
  {
    path: "/group-chat/:id",
    name: "View Group Chat",
    component: groupChatView,
  },
  {
    path: "/admin/emailList",
    exact: true,
    name: "Mailing List",
    component: EmailList,
  },
  {
    path: "/admin/performerSubscriber",
    exact: true,
    name: "Premium Content",
    component: PerformerSubscriber,
  },
  { path: "/admin/faq", exact: true, name: "FAQ", component: FaqList },
  { path: "/admin/faq/add", exact: true, name: "Add FAQ", component: FaqStore },
  { path: "/admin/faq/:id", exact: true, name: "Edit FAQ", component: FaqEdit },
  {
    path: "/admin/personalAppearance",
    exact: true,
    name: "Personal Appearances",
    component: personalAppearnceList,
  },
  {
    path: "/admin/requestLimit",
    exact: true,
    name: "Request Limits",
    component: RequestLimit,
  },

  {
    path: "/admin/personalAppearance/:id",
    exact: true,
    name: "View Personal Appearance",
    component: personalAppearnceView,
  },
  {
    path: "/admin/industries",
    exact: true,
    name: "Industries",
    component: IndustriesList,
  },
  {
    path: "/admin/industries/add",
    exact: true,
    name: "Add Industry",
    component: IndustryStore,
  },
  {
    path: "/admin/industries/:id",
    exact: true,
    name: "Edit Industry",
    component: IndustryEdit,
  },

  { path: "/admin/terms-of-services", exact: true, name: "Terms Of Services", component: TosList },
  { path: "/admin/terms-of-services/add", exact: true, name: "Add", component: TosCreate },
  { path: "/admin/terms-of-services/:id", exact: true, name: "Edit", component: TosEdit },

  { path: "/theme", name: "Theme", component: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", component: Colors },
  { path: "/theme/typography", name: "Typography", component: Typography },
  { path: "/base", name: "Base", component: Cards, exact: true },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
  { path: "/base/cards", name: "Cards", component: Cards },
  { path: "/base/carousels", name: "Carousel", component: Carousels },
  { path: "/base/collapses", name: "Collapse", component: Collapses },
  { path: "/base/forms", name: "Forms", component: BasicForms },
  { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  { path: "/base/navbars", name: "Navbars", component: Navbars },
  { path: "/base/navs", name: "Navs", component: Navs },
  { path: "/base/paginations", name: "Paginations", component: Paginations },
  { path: "/base/popovers", name: "Popovers", component: Popovers },
  { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
  { path: "/base/switches", name: "Switches", component: Switches },
  { path: "/base/tables", name: "Tables", component: Tables },
  { path: "/base/tabs", name: "Tabs", component: Tabs },
  { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  { path: "/buttons", name: "Buttons", component: Buttons, exact: true },
  { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/buttons/button-dropdowns",
    name: "Dropdowns",
    component: ButtonDropdowns,
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups,
  },
  {
    path: "/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons,
  },
  { path: "/charts", name: "Charts", component: Charts },
  { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", component: Flags },
  { path: "/icons/brands", name: "Brands", component: Brands },
  {
    path: "/notifications",
    name: "Notifications",
    component: Alerts,
    exact: true,
  },
  {
    path: "/uv-rep/transaction-list",
    exact: true,
    name: "Transaction",
    component: Transactions,
  },
  { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  { path: "/notifications/badges", name: "Badges", component: Badges },
  { path: "/notifications/modals", name: "Modals", component: Modals },
  { path: "/notifications/toaster", name: "Toaster", component: Toaster },
  { path: "/widgets", name: "Widgets", component: Widgets },
  { path: "/users", exact: true, name: "Users", component: Users },
  { path: "/users/:id", exact: true, name: "User Details", component: User },

  { path: "/admin/direct_message", name: "Messages", component: directMessage },
  { path: "/admin/meet_and_greet", name: "Meet And Greet", component: meetAndGreet },
  {
    path: "/admin/meetAndGreet/:id",
    exact: true,
    name: "View Meet And Greet",
    component: meetAndGreetView,
  },
  { path: "/admin/self_live_sales_order", name: "Self Live Sales Order", component: selfLiveSalesOrder },
  { path: "/admin/marketplace_products", name: "Markeplace Products", component: marketPlacProducts },
  { path: "/admin/marketplace_orders", name: "Markeplace Orders", component: marketPlacOrders },
  {
    path: "/admin/selfLiveSalesOrder/:id",
    exact: true,
    name: "View Self Live Sales Order",
    component: selfLiveSalesOrderView,
  },
  {
    path: "/uv-rep/wallet",
    exact: true,
    name: "Wallet",
    component: Wallet,
  },
];

export default routes;
