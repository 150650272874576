import { authHeader, handleResponse } from '../helpers';
export const marketPlaceService = {
    index,
    getProductList,
    getOrderList,
    update
};

function index(params) {
    const requestOptions = {
        method: 'GET',
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/v1/marketPlace/getBundles`)
    url.search = new URLSearchParams(params)
    return fetch(url, requestOptions).then(handleResponse);
}

function getProductList(params) {
   const requestOptions = {
    method: 'GET',
    headers: authHeader()
   };

   let url = new URL(`${process.env.REACT_APP_API_URL}/api/v1/products/shopifyLinkedProducts`);
   url.search = new URLSearchParams(params);
   return fetch(url, requestOptions).then(handleResponse);
}

function getOrderList(params) {
    const requestOptions = {
     method: 'GET',
     headers: authHeader()
    };
 
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/v1/shopify/all-orders`);
    url.search = new URLSearchParams(params);
    return fetch(url, requestOptions).then(handleResponse);
 }

function update(form, id, type) {
    const requestOptions = {  
    method: 'PUT',
    body: form,
    headers: authHeader()
  };
  
  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/${type}/${id}`, requestOptions).then(handleResponse);
}
