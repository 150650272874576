import { messageConstants, uvNewsConstants,uvStoreConstants, marketPlaceConstants } from '../constants';
import { marketPlaceService } from '../services';
import { alertActions } from './';

export const marketPlaceActions = {
    marketPlaceList,
    getProductList,
    getOrderList,
    update
}


function marketPlaceList(params) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            marketPlaceService.index(params).then(data => {
                dispatch(success(data));
                resolve(data)
            },
            error => {
                reject()
            });
        });
    };

    function success(result) { return { type: uvStoreConstants.GET_MARKETPLACE_LIST_SUCCESS, result } }
}

function getProductList(params) {
    return dispatch => {
            return new Promise((resolve, reject) => {
            marketPlaceService.getProductList(params)
                .then(
                    data => {
                        dispatch(success(data));
                        resolve(data)
                    },
                    error => {
                        dispatch(alertActions.error(error.toString()));
                        reject()
                    }
                );
            });
        };
    function success(data) { return { type: marketPlaceConstants.GET_PRODUCT_LIST_SUCCESS, payload: data } }
}

function getOrderList(params) {
    return dispatch => {
            return new Promise((resolve, reject) => {
            marketPlaceService.getOrderList(params)
                .then(
                    data => {
                        dispatch(success(data));
                        resolve(data)
                    },
                    error => {
                        dispatch(alertActions.error(error.toString()));
                        reject()
                    }
                );
            });
        };
    function success(data) { return { type: marketPlaceConstants.GET_ORDER_LIST_SUCCESS, payload: data } }
}

function update(form, id, type) {
  
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(request(form));
            marketPlaceService.update(form, id, type).then((form) => {
                dispatch(success(form, id));
                dispatch(alertActions.success("Product update successfully"));
                resolve(form);
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                reject();
            }
            );
        });
    };

    function request(form) {
    return { type: marketPlaceConstants.MARKETPLACE_UPDATE_REQUEST, form };
    }
    function success(form) {
    return { type: marketPlaceConstants.MARKETPLACE_UPDATE_SUCCESS, form };
    }
    function failure(error) {
    return { type: marketPlaceConstants.MARKETPLACE_UPDATE_FAILURE, error };
    }
}
